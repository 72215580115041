import { Box, FormControlLabel, Switch } from '@mui/material';
import VirtualizedAutocomplete, {
  AutocompleteOption,
} from '../../common/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { dictionary } from '../../../utils/dictionary';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { AccessType, TenantForm } from '../../../types/api/Tenant';
import { useAdvertiserRestrictionData } from './useAdvertiserRestrictionData';

interface Props {
  index: number;
  onDelete: (index: number) => void;
  accessType: AccessType;
  hideRestrictions?: boolean;
  disabled?: boolean;
}
export default function AdvertisersRestrictionsForm({
  index,
  onDelete,
  accessType,
  hideRestrictions = false,
  disabled = false,
}: Props) {
  const { values, setFieldValue } = useFormikContext<TenantForm>();

  const { advertisers, trafficSources, offers, isLoading } =
    useAdvertiserRestrictionData({ tenantForm: values, accessType, index });

  const advertiserOptions = useMemo(() => {
    return (
      advertisers?.map((advertiser) => ({
        value: {
          advertiserId: advertiser.advertiser_id,
          advertiserName: advertiser.advertiser_name,
        },
        label: advertiser.advertiser_name,
        id: advertiser.advertiser_id,
      })) || []
    );
  }, [advertisers]);

  const trafficSourceOptions = useMemo(() => {
    return (
      trafficSources?.map((trafficSource) => ({
        value: trafficSource.affiliate_id,
        label: trafficSource.affiliate_name,
        id: trafficSource.affiliate_id,
      })) || []
    );
  }, [trafficSources]);

  const offersOptions = useMemo(() => {
    return (
      offers?.map((offer) => ({
        value: offer.offer_id,
        label: offer.offer_name,
        id: offer.offer_id,
      })) || []
    );
  }, [offers]);

  const advertiserSelectedValue =
    advertiserOptions.find(
      (option) =>
        option.value.advertiserId === values[accessType][index]?.advertiserId,
    ) || null;

  const currentRestriction = values[accessType][index];

  const trafficSourcesAccessibleIds =
    currentRestriction?.trafficSourceAccess?.accessibleIds;

  const offersAccessibleIds = currentRestriction?.offerAccess?.accessibleIds;

  const trafficSourceSelectedValues = useMemo(() => {
    return trafficSourceOptions.filter((option) =>
      trafficSourcesAccessibleIds?.includes(option.value),
    );
  }, [trafficSourceOptions, trafficSourcesAccessibleIds]);

  const offerSelectedValues = useMemo(() => {
    return offersOptions.filter((option) =>
      offersAccessibleIds?.includes(option.value),
    );
  }, [offersOptions, offersAccessibleIds]);

  return (
    <Box display="flex" flexDirection="column" gap={4} position="relative">
      <VirtualizedAutocomplete
        loading={isLoading}
        options={advertiserOptions}
        label={dictionary.admin.advertiserSelectLabel}
        disabled={disabled}
        onChange={(event, value) => {
          if (value === null) {
            onDelete(index);
          } else {
            setFieldValue(`${accessType}[${index}]`, {
              ...value?.value,
              trafficSourceAccess: {
                accessibleIds: trafficSourcesAccessibleIds,
                isRestricted:
                  currentRestriction?.trafficSourceAccess?.isRestricted,
              },
            });
          }
        }}
        value={advertiserSelectedValue}
      />
      {!hideRestrictions && advertiserSelectedValue && (
        <FormControlLabel
          control={
            <Switch
              checked={currentRestriction?.trafficSourceAccess?.isRestricted}
              name={`${accessType}[${index}].trafficSourceAccess.isRestricted`}
              onChange={(_, value) => {
                setFieldValue(`${accessType}[${index}].trafficSourceAccess`, {
                  accessibleIds: value ? trafficSourcesAccessibleIds : [],
                  isRestricted: value,
                });
              }}
            />
          }
          label={dictionary.admin.restrictTrafficSourcesLabel}
        />
      )}
      {!hideRestrictions && advertiserSelectedValue &&
        currentRestriction?.trafficSourceAccess?.isRestricted && (
          <VirtualizedAutocomplete
            multiple
            loading={isLoading}
            options={trafficSourceOptions}
            label={dictionary.admin.trafficSourceSelectLabel}
            onChange={(event, value) => {
              setFieldValue(`${accessType}[${index}].trafficSourceAccess`, {
                accessibleIds: value.map(
                  (option: AutocompleteOption) => option.value,
                ),
                isRestricted:
                  currentRestriction?.trafficSourceAccess?.isRestricted,
              });
            }}
            value={trafficSourceSelectedValues}
          />
        )}
      {!hideRestrictions && advertiserSelectedValue && (
        <FormControlLabel
          control={
            <Switch
              checked={currentRestriction?.offerAccess?.isRestricted}
              name={`${accessType}[${index}].offerAccess.isRestricted`}
              onChange={(_, value) => {
                setFieldValue(`${accessType}[${index}].offerAccess`, {
                  accessibleIds: value ? offersAccessibleIds : [],
                  isRestricted: value,
                });
              }}
            />
          }
          label={dictionary.admin.restrictOffersLabel}
        />
      )}
      {!hideRestrictions && advertiserSelectedValue &&
        currentRestriction?.offerAccess?.isRestricted && (
          <VirtualizedAutocomplete
            multiple
            loading={isLoading}
            options={offersOptions}
            label={dictionary.admin.offersSelectLabel}
            onChange={(event, value) => {
              setFieldValue(`${accessType}[${index}].offerAccess`, {
                accessibleIds: value.map(
                  (option: AutocompleteOption) => option.value,
                ),
                isRestricted: currentRestriction?.offerAccess?.isRestricted,
              });
            }}
            value={offerSelectedValues}
          />
        )}
    </Box>
  );
}
