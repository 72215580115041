import { Box, CircularProgress, Paper } from '@mui/material';
import PageHeader from '../../common/PageHeader/PageHeader';
import { useParams } from 'react-router-dom';
import CreateUpdateClientForm from './CreateUpdateClientForm';
import { Client } from '../../../types/api/Client';
import { useGetMeQuery, useGetClientListQuery } from '../../../store/rest';
import { useMemo, useEffect, useState } from 'react';

interface Props {
  pageHeader: string;
}

export default function CreateUpdateClient({ pageHeader }: Props) {
  const { client_id } = useParams();

  const { data: clients } = useGetClientListQuery();
  const [isSystemAdmin, setSystemAdmin] = useState<boolean | null>(null);
  const [isClientAdmin, setClientAdmin] = useState<boolean | null>(null);

  const { data: me, isLoading } = useGetMeQuery();
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (me) {
      setSystemAdmin(me.isSystemAdmin);
      setClientAdmin((me.adminClientIds || []).length > 0);
    } else {
      setSystemAdmin(false);
      setClientAdmin(false);
    }
  }, [me, isLoading]);

  const client: Client | undefined = useMemo(() => {
    return client_id
      ? clients?.find((client) => client.id === client_id)
      : undefined;
  }, [clients, client_id]);

  if (isLoading || isSystemAdmin === null || isClientAdmin === null) {
    return null;
  }

  return (
    <Paper sx={{ p: 4 }}>
      <PageHeader text={pageHeader} />
      {!!client_id && !client ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <CreateUpdateClientForm client={client!} isClientAdmin={isClientAdmin} isSystemAdmin={isSystemAdmin} currentUser={me} />
      )}
    </Paper>
  );
}
