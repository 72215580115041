import React from 'react';
import { Grid } from '@mui/material';
import TableauEmbed from '../components/TableauEmbed';

export const Dashboard = () => {
  return (
    <Grid container spacing={0} style={{ margin: 0, padding: 0 }}>
      <Grid item xs={12}>
        <TableauEmbed viewUrl="https://data.cerebroanalytics.com/views/CerebroInsights/Overview" />
      </Grid>
    </Grid>
  );
};
