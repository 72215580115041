import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  Client,
  ClientIdInput,
  CreateClientInput,
  UpdateClientInput,
  DeleteClientInput,
} from '../types/api/Client';
import {
  MeResponse,
  MeUpdateInput,
  User,
  DeleteUserInput,
  CreateUserInput,
  UpdateUserInput,
  CreateClientUserInput,
  UpdateClientUserInput,
  DeleteClientUserInput,
} from '../types/api/User';

export const restApi = createApi({
  reducerPath: 'restApi',
  keepUnusedDataFor: 3000,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_REST_ENDPOINT,
    prepareHeaders: async (headers: Headers) => {
      const authSession = await fetchAuthSession();
      headers.set('Authorization', authSession?.tokens?.idToken?.toString() || '');
      return headers;
    },
  }),
  tagTypes: ['UserList', 'ClientList', 'Self'],
  endpoints: (builder) => {
    return {
      getMe: builder.query<MeResponse, void>({
        query: () => '/me',
        providesTags: ['Self']
      }),
      getClientList: builder.query<Client[], void>({
        query: () => '/clients',
        providesTags: (result) => ['ClientList'],
      }),
      getClient: builder.query<Client[], ClientIdInput>({
        query: (input: ClientIdInput) => `/clients/${input.id}`,
        providesTags: (result, error, input) => [{ type: 'ClientList', id: input.id }],
      }),
      getClientUserList: builder.query<User[], ClientIdInput>({
        query: (input: ClientIdInput) => `/clients/${input.id}/users`,
        providesTags: (result, error, input) => [{ type: 'UserList', id: input.id }],
      }),
      createClient: builder.mutation<Client, CreateClientInput>({
        query: (input: CreateClientInput) => ({
          url: '/clients',
          method: 'POST',
          body: {
            ...input.client
          },
        }),
        invalidatesTags: ['ClientList'],
      }),
      updateClient: builder.mutation<Client, UpdateClientInput>({
        query: (input: UpdateClientInput) => ({
          url: `/clients/${input.id}`,
          method: 'PUT',
          body: {
            ...input.client,
          },
        }),
        invalidatesTags: ['ClientList'],
      }),
      deleteClient: builder.mutation<Client, DeleteClientInput>({
        query: (input: DeleteClientInput) => ({
          url: `/clients/${input.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ClientList'],
      }),
      getUserList: builder.query<User[], void>({
        query: () => '/users',
        transformResponse: (response: User[]) => {
          return response.map((user: User) => ({
            ...user,
            clientNames: (user.clients || []).map((client) => client.client.name).join(', '),
          }));
        },
        providesTags: (result) => ['UserList', 'Self'],
      }),
      deleteUser: builder.mutation<void, DeleteUserInput>({
        query: (input: DeleteUserInput) => ({
          url: `/users/${input.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['UserList', 'Self'],
      }),
      createUser: builder.mutation<User, CreateUserInput>({
        query: (input: CreateUserInput) => ({
          url: '/users',
          method: 'POST',
          body: {
            ...input.user,
          },
        }),
        invalidatesTags: ['UserList', 'Self'],
      }),
      updateUser: builder.mutation<User, UpdateUserInput>({
        query: (input: UpdateUserInput) => ({
          url: `/users/${input.id}`,
          method: 'PUT',
          body: {
            ...input.user,
          },
        }),
        invalidatesTags: ['UserList', 'Self'],
      }),
      createClientUser: builder.mutation<User, CreateClientUserInput>({
        query: (input: CreateClientUserInput) => ({
          url: `/clients/${input.id}/users`,
          method: 'POST',
          body: {
            ...input.user,
          },
        }),
        invalidatesTags: ['UserList', 'Self'],
      }),
      deleteClientUser: builder.mutation<void, DeleteClientUserInput>({
        query: (input: DeleteClientUserInput) => ({
          url: `/clients/${input.id}/users/${input.permissionId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['UserList', 'Self'],
      }),
      updateClientUser: builder.mutation<User, UpdateClientUserInput>({
        query: (input: UpdateClientUserInput) => ({
          url: `/clients/${input.id}/users/${input.permissionId}`,
          method: 'PUT',
          body: {
            ...input.permission,
          },
        }),
        invalidatesTags: ['UserList', 'Self'],
      }),
      updateMe: builder.mutation<User, MeUpdateInput>({
        query: (input: MeUpdateInput) => ({
          url: '/me',
          method: 'PUT',
          body: {
            ...input,
          }
        }),
        invalidatesTags: ['Self', 'UserList'],
      }),
      impersonate: builder.mutation<void, string | null>({
        query: (input: string) => ({
          url: '/impersonate',
          method: 'POST',
          body: {
            user_id: input,
          }
        }),
        invalidatesTags: ['UserList', 'ClientList', 'Self'],
      }),

    };
  },
});

export const {
  useGetMeQuery,
  useGetClientListQuery,
  useGetClientUserListQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useGetUserListQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useCreateClientUserMutation,
  useDeleteClientUserMutation,
  useUpdateClientUserMutation,
  useUpdateMeMutation,
  useImpersonateMutation,
} = restApi;
