import { Client, ClientForm, defaultAccessItem } from '../../../types/api/Client';
import { Box, Button, TextField, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import { Formik, FieldArray } from 'formik';
import { ClientSchema } from '../../../utils/validation/ClientSchema';
import { dictionary } from '../../../utils/dictionary';
import { RouterPath } from '../../../utils/routerUtils';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  useUpdateClientMutation,
  useCreateClientMutation,
} from '../../../store/rest';
import { MeResponse } from '../../../types/api/User';
import { convertClientToClientForm } from './utils';
import ClientUserList from '../UserList/ClientUserList';
import AdvertisersRestrictionsForm from '../AdvertisersRestrictionsForm/AdvertisersRestrictionsForm';

interface Props {
  client: Client;
  isSystemAdmin: boolean;
  isClientAdmin: boolean;
  currentUser?: MeResponse;
}
export default function CreateUpdateClientForm({ client, isSystemAdmin, isClientAdmin, currentUser }: Props) {
  const [createClient, { isLoading }] = useCreateClientMutation();
  const [updateClient, { isLoading: isLoadingUpdate }] = useUpdateClientMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const accessType = 'adminAccess';

  const handleSubmit = async (values: ClientForm) => {
    const response = client
      ? await updateClient({
          id: client.id,
          client: values,
        }).unwrap()
      : await createClient({
          client: values,
        }).unwrap();

    if (typeof response !== 'string' && 'error' in response) {
      enqueueSnackbar(dictionary.actions.serverError, {
        variant: 'error',
      });
    } else {
      const successMessage = !!client
        ? dictionary.admin.updateClientSuccess
        : dictionary.admin.createClientSuccess;
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });
      navigate(RouterPath.ADMIN);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  return (
    <Box pt={4} width="70%">
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ClientSchema}
        initialValues={convertClientToClientForm(client)}
        onSubmit={handleSubmit}>
        {({
          handleSubmit,
          handleBlur,
          values,
          handleChange,
          setFieldValue,
          errors,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={6}>
              <TextField
                label="Name"
                name="name"
                onChange={handleChange}
                value={values['name']}
                onBlur={handleBlur}
                error={!!errors['name']}
                helperText={errors['name']}
                disabled={!isSystemAdmin}
              />
              <Box
                pt={4}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                gap={4}>
                <PageSubHeader text="Advertisers" />
                <FieldArray
                  name={accessType}
                  render={(arrayHelpers) => (
                    <>
                      {values[accessType]?.map((item, index) => (
                        <div key={index}>
                          <AdvertisersRestrictionsForm
                            key={item.advertiserId}
                            index={index}
                            onDelete={(i) => arrayHelpers.remove(i)}
                            accessType={accessType}
                            hideRestrictions={true}
                            disabled={!isSystemAdmin}
                          />
                        </div>
                      ))}
                    </>
                  )}
                />
                { isSystemAdmin && (
                  <Button
                    sx={{ paddingTop: '4px', alignSelf: 'flex-start' }}
                    onClick={() =>
                      setFieldValue(
                        `${accessType}[${values[accessType].length}]`,
                        defaultAccessItem,
                      )
                    }>
                    <AddIcon /> ADD ADVERTISER
                  </Button>
                )}
              </Box>
              { client?.id && (
                <ClientUserList
                  allowCreateUser={true}
                  clientId={client.id}
                  pageSubHeader="Users"
                  createButtonLabel={dictionary.admin.addUserButton}
                  currentUser={currentUser}
                />
              )}

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  onClick={handleGoBack}
                  variant="outlined"
                  sx={{ width: 'fit-content' }}
                >
                  Go Back
                </Button>
                { isSystemAdmin && (
                  <Button
                    disabled={!dirty || isLoading || isLoadingUpdate}
                    type="submit"
                    variant="contained"
                    sx={{ width: 'fit-content' }}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
