import { Box, Button, TextField, FormControlLabel, Switch, Stack } from '@mui/material';
import { Formik } from 'formik';
import { UserSchema } from '../../../utils/validation/UserSchema';
import { User, ClientUserForm } from '../../../types/api/User';
import { convertClientUserToClientUserForm} from './utils';

interface Props {
  user: User;
  handleSubmit: (values: ClientUserForm) => void;
  isLoading: boolean;
}
export default function CreateUpdateClientUserForm({ user, handleSubmit, isLoading = false }: Props) {

  return (
    <Box>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={UserSchema}
        initialValues={convertClientUserToClientUserForm(user)}
        onSubmit={handleSubmit}>
        {({
          handleSubmit,
          handleBlur,
          values,
          handleChange,
          setFieldValue,
          errors,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={6}>
              <TextField
                disabled={!!user}
                label="Email"
                name="email"
                onChange={handleChange}
                value={values['email']}
                onBlur={handleBlur}
                error={!!errors['email']}
                helperText={errors['email']}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={values.admin}
                    name="admin"
                    onChange={(_, value) => {
                      setFieldValue('admin', value);
                    }}
                  />
                }
                label="Admin User"
              />
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  sx={{ width: 'fit-content', alignSelf: 'flex-end' }}>
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
