import { Box, Button, TextField, FormControlLabel, Switch, Stack } from '@mui/material';
import { Formik, FieldArray } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { HttpError } from '../../../types/error';

import AddIcon from '@mui/icons-material/Add';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';

import { UserSchema } from '../../../utils/validation/UserSchema';
import { dictionary } from '../../../utils/dictionary';
import { RouterPath } from '../../../utils/routerUtils';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from '../../../store/rest';
import { User, UserForm, defaultUserClient } from '../../../types/api/User';
import { convertUserToUserForm} from './utils';

import ClientSelectionForm from '../ClientSelectionForm/ClientSelectionForm';

interface Props {
  user: User;
}
export default function CreateUpdateUserForm({ user }: Props) {
  const [createUser, { isLoading }] = useCreateUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate }] =
    useUpdateUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (values: UserForm) => {
    const handleError = (error: HttpError) => {
      const message = error?.data?.message ? `Server Error. ${error?.data?.message}` : dictionary.actions.serverError;
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }

    const handleSuccess = () => {
      const successMessage = !!user
        ? dictionary.admin.updateUserSuccess
        : dictionary.admin.createUserSuccess;
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });
      navigate(RouterPath.ADMIN);
    }

    const valuesToSend = {
      ...values,
      clients: values.clients.filter((client) => client.clientId),
    };

    const response = user
      ? updateUser({
          id: user.id,
          user: valuesToSend,
        }).unwrap()
      : createUser({
          user: valuesToSend,
        }).unwrap();

    await response.then(handleSuccess).catch(handleError);
  };

  const handleGoBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  return (
    <Box pt={4} width="70%">
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={UserSchema}
        initialValues={convertUserToUserForm(user)}
        onSubmit={handleSubmit}>
        {({
          handleSubmit,
          handleBlur,
          values,
          handleChange,
          setFieldValue,
          errors,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={6}>
              <TextField
                disabled={!!user}
                label="Email"
                name="email"
                onChange={handleChange}
                value={values['email']}
                onBlur={handleBlur}
                error={!!errors['email']}
                helperText={errors['email']}
              />
              <TextField
                label="First name"
                name="firstName"
                onChange={handleChange}
                value={values['firstName']}
                onBlur={handleBlur}
                error={!!errors['firstName']}
                helperText={errors['firstName']}
              />
              <TextField
                label="Last name"
                name="lastName"
                onChange={handleChange}
                value={values['lastName']}
                onBlur={handleBlur}
                error={!!errors['lastName']}
                helperText={errors['lastName']}
              />
              <MuiTelInput
                defaultCountry='US'
                placeholder="Type your phone"
                label="Phone"
                value={values.phone}
                onChange={(phoneValue) => {
                  setFieldValue('phone', phoneValue);
                }}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={values.admin}
                    name="admin"
                    onChange={(_, value) => {
                      setFieldValue('admin', value);
                    }}
                  />
                }
                label="Admin User"
              />
              { !values.admin && (
                <Box
                  pt={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  gap={4}>
                  <PageSubHeader text="Clients" />
                  <FieldArray
                    name='clients'
                    render={(arrayHelpers) => (
                      <>
                        {values.clients?.map((item, index) => (
                          <div key={item.clientId}>
                            <ClientSelectionForm
                              key={index}
                              index={index}
                              onDelete={(i) => arrayHelpers.remove(i)}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                  <Button
                    sx={{ paddingTop: '4px', alignSelf: 'flex-start' }}
                    onClick={() =>
                      setFieldValue(
                        `clients[${values.clients.length}]`,
                        defaultUserClient,
                      )
                    }>
                    <AddIcon /> ADD CLIENT
                  </Button>
                </Box>
              )}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  onClick={handleGoBack}
                  variant="outlined"
                  sx={{ width: 'fit-content' }}
                >
                  Go Back
                </Button>
                <Button
                  disabled={isLoading || isLoadingUpdate}
                  type="submit"
                  variant="contained"
                  sx={{ width: 'fit-content', alignSelf: 'flex-end' }}>
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
