import {
  getGridStringOperators,
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import LinkItem from '../../components/common/LinkItem/LinkItem';
import { Link as LinkType } from '../../types/api/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { MeResponse } from '../../types/api/User';

export const getLinkColumns = (
  handleDeleteClick: (userId: string, link: string) => () => void,
  handleTogglePrivate: (id: string, privateLink: boolean) => () => void,
  currentUser: MeResponse,
) => {
  return [
    // {
    //   field: 'nickname',
    //   headerName: 'Nickname',
    //   filterable: true,
    // },
    {
      field: 'link',
      headerName: 'Url',
      filterable: false,
      renderCell: (cellValues: any) => {
        return <LinkItem link={cellValues.value} />;
      },
      width: 500,
    },
    {
      field: 'rotator_id',
      headerName: 'Rotator Id',
      filterable: false,
      width: 72,
    },
    {
      field: 'rotator_name',
      headerName: 'Rotator name',
      width: 210,
      filterable: false,
    },
    {
      field: 'offer_name',
      headerName: 'Offer',
      type: 'string',
      width: 210,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: 'advertiser_name',
      headerName: 'Advertiser name',
      type: 'string',
      width: 210,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: 'url_name',
      headerName: 'Page name',
      width: 300,
      filterable: false,
    },
    {
      field: 'url_id',
      headerName: 'Url Id',
      filterable: false,
    },
    {
      field: 'offer_id',
      headerName: 'Offer Id',
      filterable: false,
    },
    {
      field: 'advertiser_id',
      headerName: 'Advertiser Id',
      filterable: false,
    },
    {
      field: 'private',
      headerName: 'Visibility',
      filterable: true,
      valueGetter: (params: GridValueGetterParams) => (params.value ? 'Self' : 'Team'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ row }: GridRowParams) => {
        const actions = [];
        if (row.user_id === currentUser.user.id) {
          actions.push(row.private ? (
            <GridActionsCellItem
              icon={<Tooltip title="Make Visible to Team"><Visibility /></Tooltip>}
              label="Make Visible to Team"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleTogglePrivate(row.id, !row.private)}
            />
          ) : (
            <GridActionsCellItem
              icon={<Tooltip title="Make Visible to Self"><VisibilityOff /></Tooltip>}
              label="Make Visible to Self"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleTogglePrivate(row.id, !row.private)}
            />
          ));
        }
        actions.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            sx={{
              color: 'primary.main',
            }}
            onClick={handleDeleteClick(row.userId, row.link)}
          />
        )

        return actions;
      },
    },
  ];
};

export const filterLinks = (linkList?: LinkType[], searchTerm?: string) => {
  let filtered = linkList;
  if (searchTerm && linkList) {
    filtered = linkList.filter(
      (link) =>
        link.link.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.rotator_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.offer_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.advertiser_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        link.url_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.url_id?.toString().includes(searchTerm.toLowerCase()) ||
        link.offer_id?.toString().includes(searchTerm.toLowerCase()) ||
        link.advertiser_id?.toString().includes(searchTerm.toLowerCase()),
    );
  }
  return filtered;
};
