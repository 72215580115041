import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rotatorsApi } from './rotators';
import { linksApi } from './links';
import { adminApi } from './admin';
import { restApi } from './rest';

export const store = configureStore({
  reducer: {
    [rotatorsApi.reducerPath]: rotatorsApi.reducer,
    [linksApi.reducerPath]: linksApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [restApi.reducerPath]: restApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rotatorsApi.middleware,
      linksApi.middleware,
      adminApi.middleware,
      restApi.middleware,
    ),
});

export const resetStores = () => {
  store.dispatch(rotatorsApi.util.resetApiState());
  store.dispatch(linksApi.util.resetApiState());
  store.dispatch(adminApi.util.resetApiState());
  store.dispatch(restApi.util.resetApiState());
}

setupListeners(store.dispatch);
