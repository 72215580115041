import { Box, Button, Paper } from "@mui/material";
import PageSubHeader from "../../common/PageSubHeader/PageSubHeader";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import ConfirmationDialog from "../../common/ConfirmationDialog/ConfirmationDialog";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { dictionary } from "../../../utils/dictionary";
import { getAdminListColumns } from "./utils";
import {
  useDeleteUserMutation,
  useGetUserListQuery,
  useImpersonateMutation,
} from "../../../store/rest";
import { resetStores } from "../../../store";
import { useNavigate } from 'react-router-dom';

interface Props {
  pathToCreate?: string;
  pathToUpdate?: string;
  pageSubHeader: string;
  createButtonLabel?: string;
  isSystemAdmin?: boolean;
}

export default function UserList({
  pathToCreate,
  pathToUpdate,
  pageSubHeader,
  createButtonLabel,
  isSystemAdmin,
}: Props) {
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation();
  const [impersonate, { isLoading: impersonateLoading }] = useImpersonateMutation();

  const { confirmationDialogProps, openDialog } = useConfirmDialog({
    text: dictionary.admin.deleteUserConfirmText,
  });

  const { data: users, isLoading } = useGetUserListQuery();

  const handleDelete = (id: string) => () => {
    openDialog(() => {
      deleteUser({
        id: id,
      });
    });
  };

  let navigate = useNavigate();
  const handleImpersonateClick = (id: string) => () => {
    console.log("Impersonate user with id: ", id);
    impersonate(id).unwrap().then(() => {
      resetStores();
      navigate(`/`);
    });
  };

  return (
    <Paper sx={{ p: 4, mt: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <PageSubHeader text={pageSubHeader} />
        {pathToCreate && (
          <Button component={Link} to={pathToCreate} sx={{ paddingTop: "4px" }}>
            <AddIcon /> {createButtonLabel}
          </Button>
        )}
      </Box>
      <DataGridPremium
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          pinnedColumns: {
            right: ["actions"],
          },
          columns: {
            columnVisibilityModel: {
              actions: true,
            },
          },
        }}
        pagination
        pageSizeOptions={[5, 10, 25, 50, 100]}
        loading={isLoading || deleteLoading}
        columns={getAdminListColumns(
          undefined,
          handleDelete,
          pathToUpdate,
          ['clients'],
          undefined,
          isSystemAdmin,
          handleImpersonateClick,
        )}
        rows={users || []}
        disableRowSelectionOnClick
        sx={{
          minHeight: "100px",
          "& .MuiDataGrid-virtualScroller": {
            minHeight: "100px",
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            paddingRight: "0 !important",
          },
        }}
        getRowId={(row: any) => row?.id}
      />
      <ConfirmationDialog {...confirmationDialogProps} />
    </Paper>
  );
}
