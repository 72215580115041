import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ListboxComponent } from '../AutocompleteListboxComponent/ListboxComponent';
import { StyledPopper } from './utils';
import { ListChildComponentProps } from 'react-window';
import { CircularProgress } from '@mui/material';

export interface AutocompleteOption {
  value: any;
  label: string;
  id: string | number;
  all?: boolean;
}

interface Props {
  options: Array<AutocompleteOption>;
  label?: string;
  disabled?: boolean;
  onChange?: (event: React.SyntheticEvent, value: any) => void;
  value?: AutocompleteOption | AutocompleteOption[] | null;
  onBlur?: () => void;
  error?: string;
  helperText?: string;
  required?: boolean;
  loading?: boolean;
  freeSolo?: boolean;
  ListProps?: {
    renderRow?(props: ListChildComponentProps): JSX.Element;
  };
  filterOption?: {
    matchFrom: 'any' | 'start';
    stringify: (option: AutocompleteOption | AutocompleteOption[]) => string;
  };
  multiple?: boolean;
}

export default function VirtualizedAutocomplete({
  options,
  label,
  disabled = false,
  onChange,
  value,
  onBlur,
  error,
  required,
  loading,
  helperText,
  freeSolo,
  ListProps = {},
  filterOption,
  multiple,
}: Props) {
  const onSelect = (event: React.SyntheticEvent, value: any) => {
    let newValue = value;
    if (multiple) {
      const isAllSelectedOption = value.some(
        (option: AutocompleteOption) => option.all,
      );

      if (isAllSelectedOption) {
        newValue = value.length - 1 === options.length ? [] : options;
      }
    }

    onChange?.(event, newValue);
  };

  return (
    <Autocomplete
      disableCloseOnSelect={multiple}
      multiple={multiple}
      disabled={disabled}
      freeSolo={freeSolo}
      autoSelect={freeSolo}
      loadingText="Loading..."
      loading={loading}
      id="virtualized-autocomplete"
      sx={{ width: '100%' }}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      ListboxProps={{ ...ListProps, multiple } as any}
      options={
        multiple
          ? [
              { label: 'Select All...', all: true, id: 'all', value: 'all' },
              ...options,
            ]
          : options
      }
      filterOptions={filterOption && createFilterOptions(filterOption)}
      renderInput={(params) => (
        <TextField
          required={required}
          error={!!error}
          helperText={error || helperText}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size="2rem" />}
                {params.InputProps?.endAdornment}{' '}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, state) =>
        [props, option, state.index] as React.ReactNode
      }
      onChange={onSelect}
      value={value}
      onBlur={onBlur}
      isOptionEqualToValue={(option, newValue) => {
        if (Array.isArray(option) || Array.isArray(newValue)) {
          return false;
        } else {
          return option?.id === newValue?.id;
        }
      }}
    />
  );
}
