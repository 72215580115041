import DashboardLayout from './layouts/Dashboard/Dashboard';

import { Optimize } from './pages/Optimize';
import { Dashboard } from './pages/Dashboard';
import { Create } from './pages/Create';
import { Tools } from './pages/Tools';
import { NoMatch } from './pages/NoMatch';
import { Funnels } from './pages/Funnels';
import { Profile } from './pages/Profile';
import { Route, Routes } from 'react-router-dom';
import { Rotators } from './pages/Rotators/Rotators';
import { RotatorDetails } from './components/Rotators/RotatorDetails/RotatorDetails';
import { RouterPath } from './utils/routerUtils';
import NewLinkContainer from './components/NewLink/NewLinkContainer/NewLinkContainer';
import LinksContainer from './pages/Links/LinksContainer';
import { ProtectedAdminRoute } from './components/ProtectedAdminRoute';
import CreateUpdateTenant from './components/Admin/CreateUpdateTenant/CreateUpdateTenant';
import CreateUpdateAdmin from './components/Admin/CreateUpdateAdmin/CreateUpdateAdmin';
import CreateUpdateUser from './components/Admin/CreateUpdateUser/CreateUpdateUser';
import CreateUpdateClient from './components/Admin/CreateUpdateClient/CreateUpdateClient';
import { Admin } from './pages/Admin';
import { USER_ROLES } from './utils/auth';
import { dictionary } from './utils/dictionary';
import { Offers } from './pages/Offers';

function Changelog() {
  window.location.href =
    'https://cerebro.slab.com/topics/product-release-notes-t1z0jx18';
  return null;
}

function Documentation() {
  window.location.href = 'https://support.cerebroanalytics.com/hc/en-us';
  return null;
}

export const RouterWrapper = () => {
  return (
    <Routes>
      <Route path={RouterPath.MAIN} element={<DashboardLayout />}>
        <Route path={RouterPath.MAIN} element={<Dashboard />} />
        <Route path={RouterPath.OPTIMIZE} element={<Optimize />} />
        <Route path={RouterPath.CREATE} element={<Create />} />
        <Route path={RouterPath.TOOLS} element={<Tools />} />
        <Route path={RouterPath.FUNNEL} element={<Funnels />} />
        <Route path={RouterPath.ADMIN} element={<ProtectedAdminRoute />}>
          <Route path={RouterPath.ADMIN} element={<Admin />} />
          <Route
            path={RouterPath.CREATE_TENANT}
            element={
              <CreateUpdateTenant
                userRole={USER_ROLES.TENANT_ADMIN}
                pageHeader={dictionary.admin.createTenantPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.UPDATE_TENANT}
            element={
              <CreateUpdateTenant
                userRole={USER_ROLES.TENANT_ADMIN}
                pageHeader={dictionary.admin.updateTenantPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.CREATE_CLIENT}
            element={
              <CreateUpdateClient
                pageHeader={dictionary.admin.createClientPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.UPDATE_CLIENT}
            element={
              <CreateUpdateClient
                pageHeader={dictionary.admin.updateClientPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.CREATE_USER}
            element={
              <CreateUpdateUser
                pageHeader={dictionary.admin.createUserPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.UPDATE_USER}
            element={
              <CreateUpdateUser
                pageHeader={dictionary.admin.updateUserPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.UPDATE_ADMIN}
            element={
              <CreateUpdateAdmin
                userRole={USER_ROLES.SYSTEM_ADMIN}
                pageHeader={dictionary.admin.updateAdminPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.CREATE_TENANT_USER}
            element={
              <CreateUpdateTenant
                userRole={USER_ROLES.TENANT_USER}
                pageHeader={dictionary.admin.createTenantUserPageHeader}
              />
            }
          />
          <Route
            path={RouterPath.UPDATE_TENANT_USER}
            element={
              <CreateUpdateTenant
                userRole={USER_ROLES.TENANT_USER}
                pageHeader={dictionary.admin.updateTenantUserPageHeader}
              />
            }
          />
        </Route>
        <Route path={RouterPath.DOCUMENTATION} element={<Documentation />} />
        <Route path={RouterPath.CHANGELOG} element={<Changelog />} />
        <Route path={RouterPath.PROFILE} element={<Profile />} />
        <Route path={RouterPath.OFFERS} element={<Offers />} />
        <Route path={RouterPath.ROTATORS} element={<Rotators />}>
          <Route
            path={RouterPath.ROTATOR_DETAILS}
            element={<RotatorDetails />}
          />
        </Route>
        <Route path={RouterPath.LINKS} element={<LinksContainer />} />
        <Route path={RouterPath.NEW_LINK} element={<NewLinkContainer />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};
