import {
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import TableEditLinkActionCell from '../../common/TableEditLinkActionCell/TableEditLinkActionCell';

export const getListColumns = (
  allowDelete: boolean,
  allowEdit: boolean,
  handleDeleteClick?: (id: string) => () => void,
  pathToUpdate?: string,
) => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      filterable: true,
      flex: 1,
    },
    {
      field: 'users',
      headerName: 'No. of Users',
      filterable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? (params.value as any[]).length : 0,
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      filterable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value)?.toLocaleString() : '',
      width: 200,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated at',
      filterable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value)?.toLocaleString() : '',
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id }: GridRowParams) => {
        return [
          <>
            {allowEdit && pathToUpdate && (
              <TableEditLinkActionCell
                url={pathToUpdate.replace(':client_id', id as string)}
              />
            )}
          </>,
          <>
            {allowDelete && handleDeleteClick && (
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleDeleteClick(id as string)}
              />
            )}
          </>,
        ];
      },
    },
  ];
};
