import { Outlet, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetMeQuery } from '../store/rest';

export const ProtectedAdminRoute = () => {
  const [isAdmin, setAdmin] = useState<boolean | null>(null);
  const { data: me, isLoading } = useGetMeQuery();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (me) {
      setAdmin(me.isSystemAdmin || (me.adminClientIds || []).length > 0);
    } else {
      setAdmin(false);
    }
  }, [me, isLoading]);

  if (isLoading || isAdmin === null) {
    return null;
  }

  return isAdmin ? <Outlet /> : <Navigate to="/" />;
};
