import { Typography } from '@mui/material';
import { dictionary } from '../../../utils/dictionary';

import UserList from '../UserList/UserList';
import ClientList from '../ClientList/ClientList';
import { RouterPath } from '../../../utils/routerUtils';
import { MeResponse } from '../../../types/api/User';

interface Props {
  isSystemAdmin: boolean | null;
  isClientAdmin: boolean | null;
  isLoading: boolean;
}
export default function Superamin({ isSystemAdmin, isClientAdmin, isLoading }: Props) {
  if (isLoading || isSystemAdmin === null || isClientAdmin === null) {
    return null;
  }

  return (
    <>
      <Typography variant="h3" gutterBottom display="inline">
        {dictionary.admin.superAdminPageHeader}
      </Typography>
      {(isSystemAdmin || isClientAdmin) && <ClientList
        pathToCreate={RouterPath.CREATE_CLIENT}
        pathToUpdate={RouterPath.UPDATE_CLIENT}
        pageSubHeader="Clients"
        createButtonLabel={dictionary.admin.createClientButton}
        isClientAdmin={isClientAdmin}
        isSystemAdmin={isSystemAdmin}
      />}
      {isSystemAdmin && <UserList
        pathToCreate={RouterPath.CREATE_USER}
        pathToUpdate={RouterPath.UPDATE_USER}
        pageSubHeader="Users"
        createButtonLabel={dictionary.admin.createUserButton}
        isSystemAdmin={isSystemAdmin}
      />}
    </>
  );
}
