import { User } from './User';

export interface Client {
  id: string;
  name: string;
  createdById: string;
  createdAt: string;
  updatedAt: string;
  adminAccess: AccessItem[];
  readAccess: AccessItem[];
  writeAccess: AccessItem[];
  users?: {
    id: string;
    admin: boolean;
    clientVisible: boolean;
    user: User;
  }[];
}

export interface ClientIdInput {
  id: string;
}

export type AccessType = 'adminAccess' | 'readAccess' | 'writeAccess';

export interface AccessItem {
  advertiserId: number;
  advertiserName: string;
  offerAccess?: {
    isRestricted: boolean;
    accessibleIds: number[] | null;
  };
  trafficSourceAccess?: {
    isRestricted: boolean;
    accessibleIds: number[] | null;
  };
}

export interface ClientForm {
  name: string;
  adminAccess: AccessItem[];
  readAccess: AccessItem[];
  writeAccess: AccessItem[];
}

export const DEFAULT_CLIENT_FORM: ClientForm = {
  name: '',
  adminAccess: [],
  readAccess: [],
  writeAccess: [],
};

export interface UpdateClientInput {
  id: string;
  client: ClientForm;
};

export interface CreateClientInput {
  client: ClientForm;
};

export interface DeleteClientInput {
  id: string;
};

export const defaultAccessItem = {
  advertiserId: '',
  advertiserName: '',
  offerAccess: {
    isRestricted: false,
    accessibleIds: [],
  },
  trafficSourceAccess: {
    isRestricted: false,
    accessibleIds: [],
  },
};