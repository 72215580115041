import { Box, CircularProgress, Paper } from '@mui/material';
import PageHeader from '../../common/PageHeader/PageHeader';
import { useParams } from 'react-router-dom';
import CreateUpdateUserForm from './CreateUpdateUserForm';
import { useGetUserListQuery, } from '../../../store/rest';
import { User } from '../../../types/api/User';
import { useMemo } from 'react';

interface Props {
  pageHeader: string;
}

export default function CreateUpdateUser({ pageHeader }: Props) {
  const { user_id } = useParams();

  const { data: users } = useGetUserListQuery();

  const user: User | undefined = useMemo(() => {
    return user_id
      ? users?.find((user) => user.id === user_id)
      : undefined;
  }, [users, user_id]);

  return (
    <Paper sx={{ p: 4 }}>
      <PageHeader text={pageHeader} />
      {!!user_id && !user ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <CreateUpdateUserForm user={user!} />
      )}
    </Paper>
  );
}
