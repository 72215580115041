import {
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import TableEditLinkActionCell from '../../common/TableEditLinkActionCell/TableEditLinkActionCell';
import { useImpersonateMutation } from '../../../store/rest';

import { ToggleOn, ToggleOff } from "@mui/icons-material";

export const getAdminListColumns = (
  handleToggleAdminClick?: (id: string) => () => void,
  handleDeleteClick?: (id: string) => () => void,
  pathToUpdate?: string,
  columnsToOmit?: string[],
  currentUserId?: string,
  isSystemAdmin?: boolean,
  handleImpersonateClick?: (id: string) => () => void,
) => {
  const getToggleIcon = (value: boolean) => value ? <ToggleOn /> : <ToggleOff />;

  return ([
    {
      field: 'firstName',
      headerName: 'First name',
      filterable: false,
      width: 150,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      filterable: false,
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      filterable: false,
      width: 200,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      filterable: false,
      width: 150,
    },
    {
      field: 'clients',
      headerName: 'Client Admin?',
      filterable: true,
      width: 100,
      valueGetter: (params: GridValueGetterParams) => (params.value[0].admin ? 'Yes' : 'No'),
    },
    {
      field: 'admin',
      headerName: 'Is Admin?',
      filterable: true,
      width: 100,
      valueGetter: (params: GridValueGetterParams) => (params.value ? 'Yes' : 'No'),
    },
    {
      field: 'clientNames',
      headerName: 'Clients',
      filterable: true,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      filterable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value)?.toLocaleString() : '',
      width: 200,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated at',
      filterable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value)?.toLocaleString() : '',
      width: 200,
    },
    {
      field: 'lastLogin',
      headerName: 'Last login',
      filterable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value)?.toLocaleString() : '',
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id, row }: GridRowParams) => {
        return [
          <>
            {isSystemAdmin && handleImpersonateClick && (
              <GridActionsCellItem
                icon={<SocialDistanceIcon />}
                label="View"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleImpersonateClick(id as string)}
              />
            )}
          </>,
          <>
            {isSystemAdmin && pathToUpdate && (
              <TableEditLinkActionCell
                url={pathToUpdate.replace(':user_id', id as string)}
              />
            )}
          </>,
          <>
            { isSystemAdmin && (currentUserId ? currentUserId !== id : true) && handleToggleAdminClick && (
              <GridActionsCellItem
                icon={getToggleIcon(row.clients[0].admin)}
                label="Toggle Admin"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleToggleAdminClick(id as string)}
              />
            )}
          </>,
          <>
            { isSystemAdmin && (currentUserId ? currentUserId !== id : true) && handleDeleteClick && (
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleDeleteClick(id as string)}
              />
            )}
          </>,
        ];
      },
    },
  ]).filter((column) => !columnsToOmit?.includes(column.field));
};
