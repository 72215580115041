import { useState } from "react";
import { Box, Button, Paper, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import { HttpError } from "../../../types/error";
import PageSubHeader from "../../common/PageSubHeader/PageSubHeader";
import AddIcon from "@mui/icons-material/Add";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import ConfirmationDialog from "../../common/ConfirmationDialog/ConfirmationDialog";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { dictionary } from "../../../utils/dictionary";
import { getAdminListColumns } from "./utils";
import { MeResponse, User, ClientUserForm } from "../../../types/api/User";
import CreateUpdateClientUserForm from "../CreateUpdateUser/CreateUpdateClientUserForm";

import {
  useCreateClientUserMutation,
  useUpdateClientUserMutation,
  useGetClientUserListQuery,
  useDeleteClientUserMutation,
} from "../../../store/rest";

interface Props {
  allowCreateUser: boolean;
  pageSubHeader: string;
  createButtonLabel?: string;
  clientId: string;
  currentUser: MeResponse | undefined;
}

export default function ClientUserList({
  allowCreateUser,
  pageSubHeader,
  createButtonLabel,
  clientId,
  currentUser,
}: Props) {
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteClientUserMutation();
  const [createUser, { isLoading: isLoadingCreate }] = useCreateClientUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate }] = useUpdateClientUserMutation();
  const [user, setUser] = useState<User | undefined>(undefined);

  const { confirmationDialogProps, openDialog } = useConfirmDialog({
    text: dictionary.admin.deleteUserConfirmText,
  });

  const { data: users, isLoading } = useGetClientUserListQuery({
    id: clientId,
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: ClientUserForm) => {
    const handleError = (error: HttpError) => {
      const message = error?.data?.message ? `Server Error. ${error?.data?.message}` : dictionary.actions.serverError;
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }

    const handleSuccess = () => {
      const successMessage = !!user
        ? dictionary.admin.updateUserSuccess
        : dictionary.admin.createUserSuccess;
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });
      setOpen(false);
      setUser(undefined);
    }

    const valuesToSend = {
      user: values,
      id: clientId,
    };
    const response = createUser(valuesToSend).unwrap();
    await response.then(handleSuccess).catch(handleError);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleDelete = (userId: string) => () => {
    const user = users?.find((u) => u.id === userId);
    if (!user) {
      return;
    }

    const { id: permissionId } = (user.clients || [])[0];

    if (!permissionId) {
      return;
    }
    openDialog(() => {
      deleteUser({
        id: clientId,
        permissionId,
      });
    });
  };

  const showCreateUser = () => {
    setOpen(true);
  }

  const handleToggleAdminClick = (userId: string) => () => {
    const user = users?.find((u) => u.id === userId);
    if (!user) {
      return;
    }

    const { id: permissionId, admin: currentlyAdmin } = (user.clients || [])[0];

    if (!permissionId) {
      return;
    }

    updateUser({
      id: clientId,
      permissionId,
      permission: {
        admin: !currentlyAdmin,
      },
    }).unwrap();
  };

  return (
    <Paper sx={{ mt: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <PageSubHeader text={pageSubHeader} />
        {allowCreateUser && (
          <Button onClick={showCreateUser} sx={{ paddingTop: "4px" }}>
            <AddIcon /> {createButtonLabel}
          </Button>
        )}
      </Box>
      <DataGridPremium
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          pinnedColumns: {
            right: ["actions"],
          },
          columns: {
            columnVisibilityModel: {
              actions: true,
            },
          },
        }}
        pagination
        pageSizeOptions={[5, 10, 25, 50, 100]}
        loading={isLoading || deleteLoading}
        columns={getAdminListColumns(
          handleToggleAdminClick,
          handleDelete,
          undefined,
          ['admin', 'clientNames', 'createdAt', 'updatedAt'],
          currentUser?.user?.id
        )}
        rows={users || []}
        disableRowSelectionOnClick
        sx={{
          minHeight: "100px",
          "& .MuiDataGrid-virtualScroller": {
            minHeight: "100px",
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            paddingRight: "0 !important",
          },
        }}
        getRowId={(row: any) => row?.id}
      />
      <ConfirmationDialog {...confirmationDialogProps} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <PageSubHeader text="Create User" />
          <CreateUpdateClientUserForm user={user!} handleSubmit={handleSubmit} isLoading={isLoadingCreate || isLoadingUpdate} />
        </Box>
      </Modal>
    </Paper>
  );
}
