import { DEFAULT_CLIENT_FORM, Client, ClientForm } from '../../../types/api/Client';

export const convertClientToClientForm = (client?: Client): ClientForm => {
  if (!client) {
    return DEFAULT_CLIENT_FORM;
  }
  return {
    name: client.name,
    adminAccess: client.adminAccess,
    readAccess: client.readAccess,
    writeAccess: client.writeAccess,
  };
};
