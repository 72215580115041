import * as APITypes from '../../types/api/Link';
import { CommonMutationType } from '../../types/api/common';

export const createLink = /* GraphQL */ `mutation CreateLink(
  $input: CreateLinkInput!
) {
  createLink(input: $input) {
    user_id
    link
    advertiser_id
    advertiser_name
    offer_id
    offer_name
    rotator_id
    rotator_name
    url_id
    url_name
  }
}
` as CommonMutationType<
  APITypes.CreateLinkMutationVariables,
  APITypes.CreateLinkMutation
>;

export const deleteLink = /* GraphQL */ `mutation DeleteLink(
  $input: DeleteLinkInput!
) {
  deleteLink(input: $input) {
    user_id
    link
  }
}
` as CommonMutationType<
  APITypes.DeleteLinkMutationVariables,
  APITypes.DeleteLinkMutation
>;

export const updateLink = /* GraphQL */ `mutation UpdateLink(
  $input: UpdateLinkInput!
) {
  updateLink(input: $input) {
    id
    user_id
    link
    advertiser_id
    advertiser_name
    offer_id
    offer_name
    rotator_id
    rotator_name
    url_id
    url_name
    description
    nickname
    notes
    private
    created_at
    updated_at
  }
}
` as CommonMutationType<
  APITypes.UpdateLinkMutationVariables,
  APITypes.UpdateLinkMutation
>;