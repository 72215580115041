import React from 'react';
import {
  Alert as MuiAlert,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
} from '@mui/material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { confirmResetPassword, getCurrentUser } from 'aws-amplify/auth';

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Alert = styled(MuiAlert)(spacing);

interface ProfileVerifyEmailProps {
  dialogOpen: boolean;
  setDialogOpen: (dialogOpen: boolean) => void;
  setSnackBarOpen: (snackBarOpen: boolean) => void;
  setSnackBarMsg: (snackBarMsg: string) => void;
}

export const ProfilePasswordReset = (props: ProfileVerifyEmailProps) => {
  const { dialogOpen, setDialogOpen } = props;

  interface passwordPropTypes {
    emailVerificationCode: string;
    newPassword: string;
    confirmPassword: string;
    submit: boolean;
  }

  const handlePasswordUpdate = async (
    values: passwordPropTypes,
    { setErrors, setStatus, setSubmitting }: any,
  ) => {
    try {
      const { username } = await getCurrentUser();

      await confirmResetPassword({
        username,
        newPassword: `${values.newPassword}`,
        confirmationCode: `${values.emailVerificationCode}`,
      });
      props.setSnackBarMsg('Successfully reset your password.');
      props.setSnackBarOpen(true);
      setDialogOpen(false);
    } catch (error: any) {
      const message = error.message || 'Something went wrong';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  const passwordInitialValues = {
    emailVerificationCode: '',
    newPassword: '',
    confirmPassword: '',
    submit: false,
  };

  const PasswordValidationSchema = Yup.object().shape({
    emailVerificationCode: Yup.number()
      .min(6, 'Must be at least 6 characters')
      .required('Required'),
    newPassword: Yup.string()
      .min(12, 'Must be at least 15 characters')
      .max(255)
      .required('Required'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), undefined],
      'Both password need to be the same',
    ),
  });

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          An verification code was sent to your email address. Please enter your email verification code and new password.
        </DialogContentText>

        <Formik
          initialValues={passwordInitialValues}
          validationSchema={PasswordValidationSchema}
          onSubmit={handlePasswordUpdate}>
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                type="number"
                name="emailVerificationCode"
                label="Email Verification Code"
                value={values.emailVerificationCode}
                error={Boolean(
                  touched.emailVerificationCode && errors.emailVerificationCode,
                )}
                fullWidth
                helperText={
                  touched.emailVerificationCode && errors.emailVerificationCode
                }
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="newPassword"
                label="New Password"
                value={values.newPassword}
                error={Boolean(touched.newPassword && errors.newPassword)}
                fullWidth
                helperText={touched.newPassword && errors.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="confirmPassword"
                label="Confirm password"
                value={values.confirmPassword}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword,
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}>
                  Update Password
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
