import * as APITypes from '../../types/api/Link';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listLinks = /* GraphQL */ `query Link(
  $showAll: Boolean = false,
  $showShared: Boolean = true
) {
  listLinks(show_all: $showAll, show_shared: $showShared) {
    items {
      user_id
      link
      advertiser_id
      advertiser_name
      offer_id
      offer_name
      rotator_id
      rotator_name
      url_id
      url_name
      id
      description
      nickname
      notes
      private
      user {
        first_name
        last_name
        email
      }
    }
  }
}
` as GeneratedQuery<APITypes.LinkQueryVariables, APITypes.LinkQuery>;
