import {
  Box,
  Button,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useState } from 'react';
import { getStepIcon } from './utils';
import * as React from 'react';
import { dictionary } from '../../../utils/dictionary';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

interface CustomStep {
  label: string;
  content: string | React.ReactNode;
}

interface Props {
  steps: Array<CustomStep>;
  width?: string;
  onFinish?: () => void;
  disabledNext?: boolean;
  onNextStep?: (step: number) => void;
}

export default function CustomVerticalStepper({
  steps,
  width,
  onFinish,
  disabledNext,
  onNextStep,
}: Props) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepperCollapsed, setStepperCollapsed] = useState(false);

  const handleNext = (step?: number) => {
    setActiveStep((prevActiveStep) => {
      const nextStep = step ?? prevActiveStep + 1;
      const shouldSetNext =
        activeStep !== nextStep && (!disabledNext || activeStep > nextStep);

      const returnStep = shouldSetNext ? nextStep : prevActiveStep;

      if (onNextStep) {
        onNextStep(returnStep);
      }

      return returnStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const nextStep = prevActiveStep - 1;

      if (onNextStep) {
        onNextStep(nextStep);
      }

      return nextStep;
    });
  };

  const handleFinish = () => {
    if (onFinish) {
      onFinish();
    }
  };

  return (
    <Box
      display="flex"
      width={width}
      height="100%"
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      p={4}>
      <Box
        maxWidth="18%"
        display="flex"
        justifyContent="space-between"
        pr={4}
        flex={stepperCollapsed ? 0 : 1}>
        {!stepperCollapsed && (
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{
              '& .MuiStepConnector-root': {
                marginLeft: '1.85em',
                flex: 'none',
              },
            }}>
            {steps.map((step, index) => (
              <Step key={step.label} sx={{ display: 'flex' }}>
                <StepLabel
                  onClick={() => handleNext(index)}
                  sx={{ flex: 1, cursor: 'pointer !important' }}
                  StepIconComponent={
                    getStepIcon(
                      activeStep,
                      index,
                    ) as React.ElementType<StepIconProps>
                  }
                  StepIconProps={{
                    color: 'primary',
                    sx: {
                      width: '2.2em',
                      height: '2.2em',
                    },
                  }}>
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <MenuOpenIcon
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => setStepperCollapsed((prevState) => !prevState)}
        />
      </Box>
      <Box flex={4} maxWidth={stepperCollapsed ? '98%' : '84%'}>
        <Box>{steps[activeStep]?.content}</Box>
        <Box
          sx={{ mb: 2, mt: 4 }}
          display="flex"
          gap={2}
          justifyContent="flex-end">
          {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
              Back
            </Button>
          )}
          <Button
            disabled={disabledNext}
            variant="contained"
            onClick={() => {
              if (activeStep === steps.length - 1) {
                handleFinish();
              } else {
                handleNext();
              }
            }}
            sx={{ mt: 1, mr: 1 }}>
            {activeStep === steps.length - 1
              ? `${dictionary.buttons.finish}`
              : `${dictionary.buttons.continue}`}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
