import {
  User,
  UserForm,
  ClientUserForm,
  DEFAULT_USER_FORM,
  DEFAULT_CLIENT_USER_FORM,
} from '../../../types/api/User';

export const convertUserToUserForm = (user?: User): UserForm => {
  if (!user) {
    return DEFAULT_USER_FORM;
  }
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    admin: user.admin,
    clients: (user.clients || []).map((client) => ({
      id: client.id,
      admin: client.admin,
      clientVisible: client.clientVisible,
      clientId: client.client.id,
    })),
  };
};

export const convertClientUserToClientUserForm = (user?: User): ClientUserForm => {
  if (!user) {
    return DEFAULT_CLIENT_USER_FORM;
  }
  return {
    email: user.email,
    admin: user.admin,
  };
};

