import * as APITypes from '../../types/api/PageDestination';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listCbaPageDestinations =
  /* GraphQL */ `query ListCbaPageDestinations(
  $filter: FilterInput
  $pagination: PaginationInput
  $sorting: SortingInput
) {
  listCbaPageDestinations(
    filter: $filter
    pagination: $pagination
    sorting: $sorting
  ) {
    items {
      url_id
      url_type
      rotator_id
      advertiser_id
      advertiser_name
      client_name
      offer_id
      offer_name
      offer_url
      preview_url
      rotator_content_id
      rotator_content_status
      rotator_content_weight
      rotator_name
      tracking_url_base
      tracking_url_hostname
      url_name
      url_nickname
      url_status
      key
      total_clicks
      total_conversions
      total_revenue
    }
    totalCount
  }
}
` as GeneratedQuery<
    APITypes.PageDestinationQueryVariables,
    APITypes.ListCbaAppPageDestinationsQuery
  >;
