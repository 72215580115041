import React from 'react';
import {
  Alert as MuiAlert,
  Button as MuiButton,
  TextField as MuiTextField,
  Typography,
  Box,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { MeResponse } from '../../types/api/User';
import { useUpdateMeMutation } from '../../store/rest';

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Alert = styled(MuiAlert)(spacing);

interface initialValuesType {
  firstName: string;
  lastName: string;
  email: string;
  // companyName: string;
  // streetAddressOne: string;
  // streetAddressTwo: string;
  // city: string;
  // state: string;
  // zipcode: string;
  // country: string;
  submit: boolean;
  phone: string;
}

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().max(255).required('First name is required'),
  lastName: Yup.string().max(255).required('Last name is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  // companyName: Yup.string().max(255).required('Company name is required'),
  // streetAddressOne: Yup.string()
  //   .max(255)
  //   .required('Street address 1 is required'),
  // city: Yup.string().max(255).required('City is required'),
  // state: Yup.string().max(255).required('State is required'),
  // zipcode: Yup.number().max(100000).required('Zip Code is required'),
  // country: Yup.string().max(255).required('Country is required'),
  phone: Yup.string().max(50, 'Too Long!'),
});

interface profileAttrPropsType {
  setSnackBarMsg: (msg: string) => void;
  setSnackBarOpen: (open: boolean) => void;
  currentUser?: MeResponse;
}

export function ProfileAttributes({ setSnackBarMsg, setSnackBarOpen, currentUser }: profileAttrPropsType) {
  const { user } = currentUser || {};
  const [updateMe, { isLoading }] = useUpdateMeMutation();


  const initialValues: initialValuesType = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
    // companyName: '',
    // streetAddressOne: '',
    // streetAddressTwo: '',
    // city: '',
    // state: '',
    // zipcode: '',
    // country: '',
    submit: false,
  };

  /**
   * Update the user's attributes.
   * Send email validation link.
   * Then, create the advertiser id.
   * @param values
   * @param setErrors
   * @param setStatus
   * @param setSubmitting
   */
  const handleSubmitAction = async (
    values: initialValuesType,
    { setErrors, setStatus, setSubmitting }: any,
  ) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
    };
    updateMe(payload).unwrap()
    .then((response) => {
      setSnackBarMsg('Successfully updated your profile.');
      setSnackBarOpen(true);
    })
    .catch((error) => {
      const message = error.message || error.data?.message || 'Something went wrong';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    });
  };

  return (

    <Box pt={4} width="70%">
      <Typography component="h1" variant="h1" gutterBottom>
        User Profile
      </Typography>
      {/* <Typography variant="subtitle2" align="center" gutterBottom>
        Let's get to know you so more.
      </Typography> */}
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmitAction}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              disabled={true}
            />
            <TextField
              type="text"
              name="firstName"
              label="First name"
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="lastName"
              label="Last name"
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <MuiTelInput
              defaultCountry="US"
              placeholder="Type your phone"
              label="Phone"
              fullWidth
              value={values.phone}
              onChange={(phoneValue) => {
                setFieldValue('phone', phoneValue);
              }}
              sx={{ my: 3 }}
            />
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 6}}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
