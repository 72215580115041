import { TenantForm } from '../../../types/api/Tenant';
import { Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { dictionary } from '../../../utils/dictionary';
import React from 'react';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import { useParams } from 'react-router-dom';

export default function TenantAdminInfoStep() {
  const { tenant_id } = useParams();
  const { values, setFieldValue, errors, handleChange, handleBlur } =
    useFormikContext<TenantForm>();

  return (
    <Box pt={4} ml={8} width="97%">
      <PageSubHeader text={dictionary.admin.tenantAdminInfo} />
      <Box display="flex" flexDirection="column" gap={6}>
        <TextField
          label="First name"
          name="firstName"
          onChange={handleChange}
          value={values['firstName']}
          onBlur={handleBlur}
          error={!!errors['firstName']}
          helperText={errors['firstName']}
        />
        <TextField
          label="Last name"
          name="lastName"
          onChange={handleChange}
          value={values['lastName']}
          onBlur={handleBlur}
          error={!!errors['lastName']}
          helperText={errors['lastName']}
        />
        <TextField
          disabled={!!tenant_id}
          label="Email"
          name="email"
          onChange={handleChange}
          value={values['email']}
          onBlur={handleBlur}
          error={!!errors['email']}
          helperText={errors['email']}
        />
        <MuiTelInput
          defaultCountry='US'
          placeholder="Type your phone"
          label="Phone"
          value={values.phone}
          onChange={(phoneValue) => {
            setFieldValue('phone', phoneValue);
          }}
          error={!!errors['phone']}
          helperText={errors['phone']}
        />
      </Box>
    </Box>
  );
}
