import { Box, Button, InputAdornment, Paper, TextField } from '@mui/material';
import {
  useDeleteLinkMutation,
  useUpdateLinkMutation,
  useLazyGetLinkListQuery,
} from '../../store/links';
import { useEffect, useState } from 'react';
import PageHeader from '../../components/common/PageHeader/PageHeader';
import { dictionary } from '../../utils/dictionary';
import { Link } from 'react-router-dom';
import { Link as LinkType } from '../../types/api/Link';
import AddIcon from '@mui/icons-material/Add';
import { Search } from '@mui/icons-material';
import { RouterPath } from '../../utils/routerUtils';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { filterLinks, getLinkColumns } from './utils';
import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import ConfirmationDialog from '../../components/common/ConfirmationDialog/ConfirmationDialog';
import { useSnackbar } from 'notistack';

import { useGetMeQuery } from '../../store/rest';

export default function LinksContainer() {
  const [getLinkList, { data: linkList, isLoading }] =
    useLazyGetLinkListQuery();
  const [deleteLink, { isLoading: isLoadingDelete }] = useDeleteLinkMutation();
  const [updateLink, { isLoading: isLoadingUpdate }] = useUpdateLinkMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [filteredLinks, setFilteredLinks] = useState<LinkType[]>();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const { confirmationDialogProps, openDialog } = useConfirmDialog({
    text: dictionary.links.deleteLinkConfirmText,
  });

  const [isAdmin, setAdmin] = useState<boolean | null>(null);
  const { data: me, isLoading: isUserLoading } = useGetMeQuery();

  useEffect(() => {
    if (isUserLoading) {
      return;
    }
    if (me) {
      setAdmin(me.isSystemAdmin);
    } else {
      setAdmin(false);
    }
  }, [me, isUserLoading]);

  const handleDelete = (userId: string, link: string) => () => {
    openDialog(async () => {
      const response = await deleteLink({
        user_id: userId,
        link,
      });

      if ('error' in response) {
        enqueueSnackbar(dictionary.actions.serverError, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(dictionary.links.deleteLinkSuccessMessage, {
          variant: 'success',
        });
      }
    });
  };

  const handleTogglePrivate = (id: string, privateLink: boolean) => () => {
    updateLink({
      id,
      private: privateLink,
    }).unwrap()
    .then((response) => {
      if ('error' in response) {
        enqueueSnackbar(dictionary.actions.serverError, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(dictionary.links.updateLinkSuccessMessage, {
          variant: 'success',
        });
      }
    });
  };

  useEffect(() => {
    setFilteredLinks(filterLinks(linkList, searchTerm));
  }, [linkList, searchTerm]);

  useEffect(() => {
    getLinkList({
      showAll,
      showShared: true,
    });
  }, [getLinkList, showAll]);

  if (!me) {
    return null;
  }

  return (
    <Paper sx={{ padding: 4 }}>
      <Box display="flex" justifyContent="space-between">
        <PageHeader text={dictionary.links.pageHeader} />
        {isAdmin && <Button
          onClick={() => setShowAll(!showAll)}
          sx={{ paddingTop: '4px' }}>
          Show All Links ({ showAll ? 'ON' : 'OFF' })
        </Button>}
      </Box>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <TextField
          id="standard-basic"
          placeholder="Search"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        <Button
          component={Link}
          to={RouterPath.NEW_LINK}
          sx={{ paddingTop: '4px' }}>
          <AddIcon /> CREATE TRACKING LINK
        </Button>
      </Box>
      <DataGridPremium
        sx={{ height: 'calc(100vh - 350px)' }}
        loading={isLoading || isLoadingDelete}
        columns={getLinkColumns(handleDelete, handleTogglePrivate, me)}
        rows={filteredLinks || []}
        pagination
        autoPageSize

        initialState={{
          pinnedColumns: {
            right: ['actions'],
          },
        }}
      />
      <ConfirmationDialog {...confirmationDialogProps} />
    </Paper>
  );
}
