export const dictionary = {
  rotators: {
    sidePanelTitle: 'Rotators',
  },
  links: {
    sidePanelTitle: 'Tracking Links',
    pageHeader: 'Tracking Links',
    newLink: {
      header: 'Generate New Tracking Link',
      advertiserStep: 'Advertiser',
      trafficStep: 'Traffic',
      pageStep: 'Link',
      parametersStep: 'Parameters',
      confirmationStep: 'Confirmation',
      advertiserStepHeader: 'Advertiser',
      advertiserSelectLabel: 'Select Advertiser',
      trafficSelectLabel: 'Select Traffic Source',
      trafficStepHeader: 'Traffic Source',
      pageSelectLabel: 'Choose Page Destination',
      pageStepHeader: 'Page Destination',
      confirmationStepHeader: 'Final Link Output',
      parametersStepHeader: 'Manual url parameters',
      success: 'Generated link saved successfully',
    },
    deleteLinkConfirmText:
      'Are you sure you want to delete this url? It cannot be undone.',
      deleteLinkSuccessMessage: 'Url deleted successfully',
      updateLinkSuccessMessage: 'Url updated successfully',
  },
  admin: {
    pageHeader: 'Admin panel',
    superAdminPageHeader: ' Admin panel',
    tenantAdminPageHeader: ' Tenant admin panel',
    tenantUsersTableHeader: ' Tenant users',
    createGroupPageHeader: 'Create new group',
    updateGroupPageHeader: 'Update group',
    createTenantPageHeader: 'Create new tenant',
    updateTenantPageHeader: 'Update tenant',
    createAdminPageHeader: 'Create new admin user',
    updateAdminPageHeader: 'Update admin user',
    createUserPageHeader: 'Create new user',
    updateUserPageHeader: 'Update user',
    createClientPageHeader: 'Create new client',
    updateClientPageHeader: 'Update client',
    createTenantUserPageHeader: 'Create new tenant user',
    updateTenantUserPageHeader: 'Update tenant user',
    userListHeader: 'Users',
    changeUserAttributeSuccess: 'User updated successfully',
    createGroupPermissionSuccess: 'Group created successfully',
    updateGroupPermissionSuccess: 'Group updated successfully',
    deleteGroupPermissionSuccess: 'Group deleted successfully',
    createUserSuccess: 'User created successfully',
    updateUserSuccess: 'User updated successfully',
    createClientSuccess: 'Client created successfully',
    updateClientSuccess: 'Client updated successfully',
    createTenantSuccess: 'Tenant created successfully',
    updateTenantSuccess: 'Tenant updated successfully',
    deleteGroupPermissionConfirmText:
      'Are you sure you want to delete this group? It cannot be undone.',
    deleteUserConfirmText:
      'Are you sure you want to delete this user? It cannot be undone.',
    deleteTenantConfirmText:
      'Are you sure you want to delete this tenant? It cannot be undone.',
    deleteClientConfirmText:
      'Are you sure you want to delete this client? It cannot be undone.',
    tenantAdminInfo: 'Tenant admin info',
    adminAccessStep: 'Admin access',
    adminAccessStepHeader: 'Admin access settings',
    readAccessStep: 'Read access',
    readAccessStepHeader: 'Read access settings',
    writeAccessStep: 'Write access',
    writeAccessStepHeader: 'Write access settings',
    advertiserSelectLabel: 'Select Allowed Advertiser',
    trafficSourceSelectLabel: 'Select Accessible Traffic Sources',
    offersSelectLabel: 'Select Accessible Offers',
    restrictTrafficSourcesLabel: 'Restrict traffic sources',
    restrictOffersLabel: 'Restrict offers',
    createAdminButton: 'CREATE ADMIN',
    createClientButton: 'CREATE CLIENT',
    createUserButton: 'CREATE USER',
    addUserButton: 'ADD USER',
    createTenantAdminButton: 'CREATE TENANT ADMIN',
    createTenantUser: 'CREATE TENANT USER',
  },
  buttons: {
    finish: 'Finish',
    continue: 'Continue',
    goBack: 'GO BACK',
  },
  actions: {
    serverError: 'Server error. Please try again later',
  },
};
