import { AccessItem, Client } from './Client';
interface UserAttribute {
  Name: string;
  Value: string;
}

export interface MeResponse {
  user: User,
  isSystemAdmin: boolean;
  allowedAdvertiserIds: number[];
  allowedTrafficSourceIds: number[];
  adminClientIds?: string[];
  originalUser: User;
  impersonating: boolean;
}

export interface MeUpdateInput {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export interface CognitoUserType {
  Attributes: UserAttribute[];
  Enabled: boolean;
  UserCreateDate: number;
  UserLastModifiedDate: number;
  Username: string;
  UserStatus: string;
}

export interface ListUsersResponse {
  PaginationToken?: string;
  Users: CognitoUserType[];
}

export interface UserType {
  Enabled: boolean;
  UserCreateDate: number;
  UserLastModifiedDate: number;
  Username: string;
  UserStatus: string;
  email: string;
  groupName: string;
  'custom:group_id': string;
}

export interface User {
  id: string;
  sub: string;
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  createdAt: string;
  updatedAt: string;
  lastLogin?: string;
  admin: boolean;
  clients?: {
    id: string;
    admin: boolean;
    clientVisible: boolean;
    adminAccess: AccessItem;
    readAccess: AccessItem;
    writeAccess: AccessItem;
    client: Client;
  }[];
}

export interface DeleteUserInput {
  id: string;
}

export interface UserClientForm {
  clientId: string;
  admin: boolean;
  clientVisible: boolean;
  id?: string;
}

export interface UserForm {
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  admin: boolean;
  clients: UserClientForm[];
}

export interface ClientUserForm {
  email: string;
  admin: boolean;
}

export interface CreateUserInput {
  user: UserForm;
}

export interface UpdateUserInput {
  id: string;
  user: UserForm;
}

export interface CreateClientUserInput {
  id: string;
  user: ClientUserForm;
}

export interface UpdateClientUserInput {
  id: string;
  permissionId: string;
  permission: {
    admin?: boolean;
    clientVisible?: boolean;
  }
}

export interface DeleteClientUserInput {
  id: string;
  permissionId: string;
}

export const DEFAULT_USER_FORM: UserForm = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  admin: false,
  clients: [],
}

export const DEFAULT_CLIENT_USER_FORM: ClientUserForm = {
  email: '',
  admin: false,
}

export const defaultUserClient = {
  clientId: '',
  admin: false,
}
