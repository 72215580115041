import { Box, FormControlLabel, Switch } from '@mui/material';
import VirtualizedAutocomplete from '../../common/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useClientSelectionData } from './useClientSelectionData';
import { UserForm } from '../../../types/api/User';

interface Props {
  index: number;
  onDelete: (index: number) => void;
}
export default function ClientSelectionForm({
  index,
  onDelete,
}: Props) {
  const { values, setFieldValue } = useFormikContext<UserForm>();
  const { clients, isLoading } = useClientSelectionData({ userForm: values, index });

  const unavailableClients = values.clients.map((client) => client.clientId).filter((clientId) => clientId !== values.clients[index]?.clientId);
  const clientOptions = useMemo(() => {
    return (
      clients
      ?.filter((client) => !unavailableClients.includes(client.id))
      ?.map((client) => ({
        value: {
          clientId: client.id,
          id: null,
          admin: false,
        },
        label: client.name,
        id: client.id,
      })) || []
    );
  }, [clients, unavailableClients]);

  const clientSelectedValue =
    clientOptions.find(
      (option) =>
        option.value.clientId === values.clients[index]?.clientId,
    ) || null;

  return (
    <Box display="flex" flexDirection="row" gap={4} position="relative" alignItems="center">
      <VirtualizedAutocomplete
        loading={isLoading}
        options={clientOptions}
        label="Cients"
        onChange={(event, value) => {
          if (value === null) {
            onDelete(index);
          } else {
            setFieldValue(`clients[${index}]`, {
              ...value?.value,
            });
          }
        }}
        value={clientSelectedValue}
      />
      <Box display="flex" flexDirection="column" width={150}>
        {clientSelectedValue && (
          <FormControlLabel
            control={
              <Switch
                checked={values.clients[index].admin}
                name='admin'
                onChange={(_, value) => {
                  setFieldValue(`clients[${index}].admin`, value);
                }}
              />
            }
            label='Admin'
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" width={150}>
        {clientSelectedValue && (
          <FormControlLabel
            control={
              <Switch
                checked={values.clients[index].clientVisible !== undefined ? values.clients[index].clientVisible : true}
                name='clientVisible'
                onChange={(_, value) => {
                  setFieldValue(`clients[${index}].clientVisible`, value);
                }}
              />
            }
            label='Client Visible'
          />
        )}
      </Box>
    </Box>
  );
}
