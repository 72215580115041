import { fetchAuthSession } from 'aws-amplify/auth';
import { MeResponse } from '../types/api/User';

export async function detectIsAdmin() {
  const { isSystemAdmin } = await fetchUser();
  return isSystemAdmin;
}

export async function getUserRole(): Promise<TYPE_USER_ROLES> {
  // const session = await fetchAuthSession();
  // const userRole = session?.tokens?.idToken?.payload[
  //   'custom:userRole'
  // ] as string;
  // return userRole as TYPE_USER_ROLES;
  return 'SYSTEM_ADMIN';
}

export async function getTenantId(): Promise<string> {
  // const session = await fetchAuthSession();
  // const userRole = session?.tokens?.idToken?.payload[
  //   'custom:tenantId'
  // ] as string;
  // return userRole as string;
  return 'SYSTEM_ADMIN';
}

export const USER_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  TENANT_ADMIN: 'TENANT_ADMIN',
  TENANT_USER: 'TENANT_USER',
} as const;

export type TYPE_USER_ROLES = typeof USER_ROLES[keyof typeof USER_ROLES];

export async function fetchUser(): Promise<MeResponse> {
  const session = await fetchAuthSession();
  const jwt = session?.tokens?.idToken?.toString() as string;
  const response = await fetch(`${process.env.REACT_APP_REST_ENDPOINT}/me`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user');
  }

  return response.json();
}
