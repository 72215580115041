import styled from '@emotion/styled';
import { Link, Paper, Typography } from '@mui/material';
import {
  Authenticator,
  CheckboxField,
  useAuthenticator,
  View,
} from '@aws-amplify/ui-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as React from 'react';

const Wrapper = styled(Paper)`
  padding: 2rem 2rem 0 2rem;
`;

export const components = {
  SignIn: {
    Header() {
      // const { toSignUp } = useAuthenticator();

      return (
        <Wrapper>
          <Typography
            variant="h3"
            align="left"
            gutterBottom
            style={{ fontWeight: 600 }}>
            Sign in to Cerebro Analytics
          </Typography>
          {/* <Typography component="h3" variant="body1" align="left">
            <Link onClick={toSignUp}> New? Let's sign you up.</Link>
          </Typography> */}
        </Wrapper>
      );
    },
    Footer() {
      const BottomFooter = styled(View)`
        padding: 0 2rem 2rem 2rem;
      `;
      const { toForgotPassword } = useAuthenticator();

      return (
        <BottomFooter textAlign="center">
          <Link onClick={toForgotPassword}> Forgot your password?</Link>
        </BottomFooter>
      );
    },
  },
  SignUp: {
    Header() {
      return (
        <>
          <Wrapper>
            <AccountCircleIcon className={'signUpIcon'} />
            <Typography
              variant="h3"
              align="left"
              gutterBottom
              style={{ fontWeight: 600 }}>
              Create your account
            </Typography>
            <Typography component="h3" variant="body1" align="left">
              Tell us a bit about yourself. We just need the basics.
            </Typography>
          </Wrapper>
        </>
      );
    },
    Footer() {
      const BottomFooter = styled(View)`
        padding: 0 2rem 2rem 2rem;
      `;
      // const { toSignIn } = useAuthenticator();

      return (
        <BottomFooter textAlign="center">
          <Typography variant={'body1'}>
            We"ll send you a verification code via SMS
          </Typography>
          <br />
          <Typography variant={'body2'}>
            By continuing, I agree to Cerebro's
            <Link variant={'body2'} href="#">
              {' '}
              Terms of Service{' '}
            </Link>
            and authorize Cerebro to obtain, use and share consumer reports
            about me.
          </Typography>
          <br />
          {/* <Button>
            <Link onClick={toSignIn}> Navigate to Sign-In</Link>
          </Button> */}
        </BottomFooter>
      );
    },
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          {/* Append & require Terms and Conditions field to sign up  */}
          <CheckboxField
            inputStyles={{
              color: 'red',
              alignSelf: 'flex-start',
              marginTop: 6,
            }}
            errorMessage={validationErrors.acknowledgement as string}
            hasError={!!validationErrors.acknowledgement}
            name="custom:acknowledgement"
            value="yes"
            label="I agree to receive updates via SMS messages.
              In certain cases, communication surcharges may apply.
              You may opt out at any time by replying with the word 'STOP'.
              We do not give your contact information to third parties."
          />
        </>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      return (
        <Typography align={'center'} variant={'h3'}>
          <strong>We just texted you</strong>
        </Typography>
      );
    },
    Footer() {
      const { resendCode } = useAuthenticator();
      return (
        <Link onClick={resendCode} className={'centerLink'}>
          Didn't get a code?
        </Link>
      );
    },
  },
};

export const formFields = {
  signUp: {
    // phone_number: {
    //   dialCode: '+1',
    //   order: 1,
    // },
  },
};
