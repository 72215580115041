import React from 'react';
import VirtualizedAutocomplete, {
  AutocompleteOption,
} from '../../common/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { dictionary } from '../../../utils/dictionary';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import { useGetAffiliatesListQuery } from '../../../store/links';
import { renderTrafficRow } from './utils';

export default function TrafficStep() {
  const { values, setFieldValue, errors, touched, setTouched } =
    useFormikContext<RotatorLinkForm>();

  const { data: affiliates, isLoading } = useGetAffiliatesListQuery({
    filter: {
      items: [
        {
          field: 'advertiser_id',
          operator: '=',
          value: `${values.advertiser?.advertiser_id}`,
        },
      ],
    },
  });

  const options =
    affiliates
      ?.map((affiliate) => ({
        value: affiliate,
        label: affiliate?.affiliate_name,
        id: affiliate?.affiliate_id,
      }))
      .sort((a, b) => a.value.total_volume - b.value.total_volume) || [];

  const selectedValue =
    options.find(
      (option) =>
        option.value?.affiliate_name === values.affiliate?.affiliate_name,
    ) || null;

  return (
    <Box>
      <PageSubHeader text={dictionary.links.newLink.trafficStepHeader} />
      <VirtualizedAutocomplete
        required
        // @ts-ignore
        onBlur={() => setTouched({ ...touched, affiliate: true })}
        loading={isLoading}
        options={options}
        label={dictionary.links.newLink.trafficSelectLabel}
        onChange={(event, value) => setFieldValue('affiliate', value?.value)}
        value={selectedValue}
        error={touched.affiliate && (errors.affiliate as string)}
        ListProps={{
          renderRow: renderTrafficRow,
        }}
        filterOption={{
          matchFrom: 'any',
          stringify: (option: AutocompleteOption | AutocompleteOption[]) =>
            JSON.stringify((option as AutocompleteOption).value)
        }}
      />
    </Box>
  );
}
