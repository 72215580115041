import styled from '@emotion/styled';
import { Badge, Grid, Avatar, Typography } from '@mui/material';

import { useGetMeQuery } from '../../store/rest';

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
  text-overflow: ellipsis;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { data: me } = useGetMeQuery();

  const getName = () => {
    if (!me) {
      return '';
    }
    return `${me.user.firstName} ${me.user.lastName}`;
  }

function stringAvatar(name: string, impersonating: boolean = false) {
  const sx = impersonating ? {
    bgcolor: 'error.main',
  } : {};
  return {
    sx,
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

  return (
    <Footer {...rest}>
      <Grid container spacing={2} wrap="nowrap" >
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot">
            {me?.user && (
              <Avatar
                {...stringAvatar(`${me.user.firstName} ${me.user.lastName}`, me.impersonating)}
                alt={`${me.user.firstName} ${me.user.lastName}`}
                src="/static/img/avatars/avatar-1.jpg"
              />
            )}
          </FooterBadge>
        </Grid>
        <Grid item>
          {me?.user && (
            <FooterText variant="body2">{getName()}</FooterText>
          )}
          {me?.user && (
            <FooterSubText variant="caption">{`${me.user.email}`}</FooterSubText>
          )}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
