import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';

export const configureAmplify = () => {
  Amplify.configure(
    {
      API: {
        REST: {
          adminRestAPI: {
            endpoint: process.env.REACT_APP_REST_ENDPOINT!,
          },
        },
        GraphQL: {
          endpoint: process.env.REACT_APP_API_ENDPOINT!,
          defaultAuthMode: 'userPool',
        },
      },
      Auth: {
        Cognito: {
          userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID!,
          userPoolId: process.env.REACT_APP_USER_POOL_ID!,
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
          loginWith: {
            email: true,
          },
          allowGuestAccess: true,
          mfa: {
            smsEnabled: true,
            status: 'off',
            totpEnabled: false,
          },
          passwordFormat: {
            minLength: 8,
            requireLowercase: false,
            requireNumbers: false,
            requireSpecialCharacters: false,
            requireUppercase: false,
          },
          userAttributes: {
            email: {
              required: true,
            },
          },
        },
      },
    },
    {
      API: {
        GraphQL: {
          headers: async () => {
            return {
              Authorization: (
                await fetchAuthSession()
              ).tokens?.idToken?.toString() as string,
            };
          },
        },
        REST: {
          headers: async () => {
            return {
              Authorization: `Bearer ${(
                await fetchAuthSession()
              ).tokens?.idToken?.toString()}`,
            };
          },
        },
      },
    },
  );
};
