import React, { useEffect, useRef, useState } from 'react';

interface TableauEmbedProps {
  viewUrl: string;
}

const TableauEmbed: React.FC<TableauEmbedProps> = ({ viewUrl }) => {
  const vizRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  // Function to calculate available space
  const updateDimensions = () => {
    if (vizRef.current) {
      const navbarHeight = 64; // Top navbar height
      const sidebarWidth = 280; // Increased sidebar width
      const horizontalPadding = 48; // Increased padding on sides
      const verticalPadding = 32; // Padding top/bottom
      
      setDimensions({
        height: window.innerHeight - navbarHeight - verticalPadding,
        width: window.innerWidth - sidebarWidth - horizontalPadding
      });
    }
  };

  // Set up resize listener
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  // Handle Tableau embed
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://data.cerebroanalytics.com/javascripts/api/tableau.embedding.3.latest.min.js';
    document.head.appendChild(script);

    script.onload = () => {
      const vizElement = document.createElement('tableau-viz') as any;
      vizElement.setAttribute('id', 'tableau-viz');
      vizElement.setAttribute('src', viewUrl);
      vizElement.setAttribute('width', `${dimensions.width}px`);
      vizElement.setAttribute('height', `${dimensions.height}px`);
      vizElement.setAttribute('toolbar', 'bottom');
      
      if (vizRef.current) {
        vizRef.current.innerHTML = '';
        vizRef.current.appendChild(vizElement);
      }
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [viewUrl, dimensions]);

  return (
    <div 
      ref={vizRef} 
      style={{ 
        width: `${dimensions.width}px`,
        height: `${dimensions.height}px`,
        overflow: 'hidden',
        padding: '0 24px', // Added some container padding
      }} 
    />
  );
};

export default TableauEmbed;