export enum RouterPath {
  MAIN = '/',
  OPTIMIZE = '/optimize',
  CREATE = '/create',
  TOOLS = '/tools',
  FUNNEL = '/tools/funnel',
  ADMIN = '/admin',
  CREATE_CLIENT = '/admin/client/create',
  UPDATE_CLIENT = '/admin/client/update/:client_id',
  CREATE_USER = '/admin/user/create',
  UPDATE_USER = '/admin/user/update/:user_id',
  CREATE_TENANT = '/admin/tenant/create',
  UPDATE_TENANT = '/admin/tenant/update/:user_id',
  UPDATE_ADMIN = '/admin/update/:user_id',
  CREATE_TENANT_USER = '/admin/tenantUser/create',
  UPDATE_TENANT_USER = '/admin/tenantUser/update/:user_id',
  DOCUMENTATION = '/documentation',
  CHANGELOG = '/changelog',
  PROFILE = '/profile',
  ROTATORS = '/rotators',
  ROTATOR_DETAILS = 'details/:rotator_content_id/:rotator_id',
  NEW_LINK = '/links/new-link',
  LINKS = '/links',
  OFFERS = '/offers',
}
