import { UserForm } from '../../../types/api/User';

import { useGetClientListQuery } from '../../../store/rest';

interface Props {
  userForm: UserForm;
  index: number;
}

export const useClientSelectionData = ({
  userForm,
  index,
}: Props) => {

  const { data: allClients, isLoading: isLoadingClients } = useGetClientListQuery();

  const getClients = () => {
    return allClients;
  }
  const clients = getClients();

  const isLoading = isLoadingClients;

  return {
    clients,
    isLoading,
  };
};
