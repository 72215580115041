import React from 'react';
import { Typography, Paper } from '@mui/material';
import PageHeader from '../components/common/PageHeader/PageHeader';

export const Offers = () => {
  return (
    <Paper sx={{ padding: 4 }}>
      <PageHeader text="Offers" />
      <Typography variant="body1">
        This is the Offers page content.
      </Typography>
    </Paper>
  );
};