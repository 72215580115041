import React, { useEffect, useState } from 'react';

import Superamin from '../components/Admin/Superadmin/Superamin';
import { useGetMeQuery } from '../store/rest';


export const Admin = () => {
  const [isSystemAdmin, setSystemAdmin] = useState<boolean | null>(null);
  const [isClientAdmin, setClientAdmin] = useState<boolean | null>(null);

  const { data: me, isLoading } = useGetMeQuery();
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (me) {
      setSystemAdmin(me.isSystemAdmin);
      setClientAdmin((me.adminClientIds || []).length > 0);
    } else {
      setSystemAdmin(false);
      setClientAdmin(false);
    }
  }, [me, isLoading]);

  if (isLoading || isSystemAdmin === null || isClientAdmin === null) {
    return null;
  }

  return (
    <React.Fragment>
      {(isSystemAdmin || isClientAdmin) && me && (
        <Superamin isClientAdmin={isClientAdmin} isSystemAdmin={isSystemAdmin} isLoading={isLoading} />
      )}
    </React.Fragment>
  );
};
