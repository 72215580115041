import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateClient } from 'aws-amplify/api';
import { CreateLinkInput, DeleteLinkInput, UpdateLinkInput, Link, LinkQueryVariables } from '../types/api/Link';
import { listLinkTrackingTemplates } from '../graphql/linkTrackingTemplates/queries';
import { listCbaAppTrafficSources } from '../graphql/trafficSources/queries';
import { createLink, deleteLink, updateLink } from '../graphql/link/mutation';
import { listLinks } from '../graphql/link/queries';
import { LinkTrackingTemplates } from '../types/api/LinkTrackingTemplates';
import { CbaAppTrafficSources } from '../types/api/TrafficSources';
import {
  ListCbaAppPageDestinationsResponse,
  PageDestinationQueryVariables,
} from '../types/api/PageDestination';
import { listCbaPageDestinations } from '../graphql/pageDestination/queries';
import { Affiliate, AffiliateQueryVariables } from '../types/api/Affiliate';
import { listCbaAffiliates } from '../graphql/affiliate/quieries';
import { Audience, AudienceQueryVariables } from '../types/api/Audience';
import { listCbaAudiences } from '../graphql/audience/quieries';
import { Advertiser } from '../types/api/Advertiser';
import { listCbaAdvertisers } from '../graphql/advertiser/quieries';
import { listCbaOffers } from '../graphql/offer/quieries';
import { Offer, OfferQueryVariables } from '../types/api/Offer';

const client = generateClient();

export const linksApi = createApi({
  reducerPath: 'linksApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/graphql' }),
  tagTypes: ['Links'],
  keepUnusedDataFor: 300,
  endpoints: (builder) => {
    return {
      getLinkTrackingTemplatesList: builder.query<
        LinkTrackingTemplates[],
        void
      >({
        async queryFn() {
          try {
            const result = await client.graphql({
              query: listLinkTrackingTemplates,
            });
            return {
              data: result.data?.listLinkTrackingTemplates?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
      }),
      getTrafficSourcesList: builder.query<CbaAppTrafficSources[], void>({
        async queryFn() {
          try {
            const result = await client.graphql({
              query: listCbaAppTrafficSources,
            });
            return {
              data: result.data?.listCbaAppTrafficSources?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
      }),
      createLink: builder.mutation<Link, CreateLinkInput>({
        async queryFn(input) {
          try {
            const result = await client.graphql({
              query: createLink,
              variables: { input },
            });
            return {
              data: result.data || null,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        invalidatesTags: ['Links'],
      }),
      getLinkList: builder.query<Link[], LinkQueryVariables>({
        async queryFn({ showAll, showShared }) {
          try {
            const result = await client.graphql({
              query: listLinks,
              variables: { showAll, showShared },
            });

            return {
              data: result.data?.listLinks?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
        providesTags: ['Links'],
      }),
      getPageDestinationList: builder.query<
        ListCbaAppPageDestinationsResponse,
        PageDestinationQueryVariables
      >({
        async queryFn(variables) {
          try {
            const result = await client.graphql({
              query: listCbaPageDestinations,
              variables: variables,
            });

            return {
              data: result.data?.listCbaPageDestinations,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        keepUnusedDataFor: 600,
      }),
      getAffiliatesList: builder.query<
        Affiliate[],
        AffiliateQueryVariables | undefined
      >({
        async queryFn(variables) {
          try {
            const result = await client.graphql({
              query: listCbaAffiliates,
              variables: variables,
            });

            return {
              data: result.data?.listCbaAffiliates?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
      }),
      getAudiencesList: builder.query<
        Audience[],
        AudienceQueryVariables | undefined
      >({
        async queryFn(variables) {
          try {
            const result = await client.graphql({
              query: listCbaAudiences,
              variables: variables,
            });

            return {
              data: result.data?.listCbaAudiences?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
      }),
      getOffersList: builder.query<Offer[], OfferQueryVariables | undefined>({
        async queryFn(variables) {
          try {
            const result = await client.graphql({
              query: listCbaOffers,
              variables: variables,
            });

            return {
              data: result.data?.listCbaOffers?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
      }),
      getAdvertisersList: builder.query<Advertiser[], void>({
        async queryFn() {
          try {
            const result = await client.graphql({
              query: listCbaAdvertisers,
            });

            return {
              data: result.data?.listCbaAdvertisers?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
      }),
      deleteLink: builder.mutation<Link, DeleteLinkInput>({
        async queryFn(input) {
          try {
            const result = await client.graphql({
              query: deleteLink,
              variables: { input },
            });
            return {
              data: result.data || null,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        invalidatesTags: ['Links'],
      }),
      updateLink: builder.mutation<Link, UpdateLinkInput>({
        async queryFn(input) {
          try {
            const result = await client.graphql({
              query: updateLink,
              variables: { input },
            });
            return {
              data: result.data || null,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        invalidatesTags: ['Links'],
      })
    };
  },
});

export const {
  useGetLinkTrackingTemplatesListQuery,
  useGetTrafficSourcesListQuery,
  useCreateLinkMutation,
  useLazyGetLinkListQuery,
  useGetPageDestinationListQuery,
  useGetAffiliatesListQuery,
  useGetAudiencesListQuery,
  useGetAdvertisersListQuery,
  useGetOffersListQuery,
  useDeleteLinkMutation,
  useUpdateLinkMutation,
} = linksApi;
