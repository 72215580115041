import * as React from 'react';
import { ThemeProvider, createTheme, Authenticator, Heading, Text } from '@aws-amplify/ui-react';
import { AuthUser } from 'aws-amplify/auth';
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator';
import { formFields } from './utils';
import './AuthenticatorWrapper.css';

const theme = createTheme({
  name: 'modern-theme',
  tokens: {
    colors: {
      // ... existing color tokens ...
    },
    components: {
      button: {
        // ... existing button styles ...
      },
    },
  },
});

const components = {
  Header() {
    return (
      <div className="auth-header">
        <img
          alt="Cerebro Analytics logo"
          src="https://cerebro-data-viz-server.s3.us-west-2.amazonaws.com/cerebro_brain_fullsize_500x425.png"
          className="auth-logo"
        />
        <Heading level={3} className="auth-title">Welcome to Cerebro Analytics</Heading>
        <Text className="auth-subtitle">Sign in to your account</Text>
      </div>
    );
  },
  Footer() {
    return (
      <div className="auth-footer">
        <Text>&copy; 2024 Cerebro Analytics by EyeBall Division, LLC</Text>
      </div>
    );
  },
};

interface Props {
  children:
    | React.ReactNode
    | ((props: { signOut?: SignOut; user?: AuthUser }) => React.ReactElement);
}

export default function AuthenticatorWrapper({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        components={components}
        formFields={formFields}
        initialState="signIn"
      >
        {({ signOut, user }): React.ReactElement => {
          if (user) {
            return typeof children === 'function'
              ? children({ signOut, user })
              : <>{children}</>;
          }
          return (
            <div className="auth-wrapper">
              <div className="auth-container">
                <Authenticator />
              </div>
            </div>
          );
        }}
      </Authenticator>
    </ThemeProvider>
  );
}

