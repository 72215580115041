import { Box, Button, Paper } from "@mui/material";
import PageSubHeader from "../../common/PageSubHeader/PageSubHeader";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import ConfirmationDialog from "../../common/ConfirmationDialog/ConfirmationDialog";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { dictionary } from "../../../utils/dictionary";
import { getListColumns } from "./utils";
import {
  useGetClientListQuery,
  useDeleteClientMutation,
} from "../../../store/rest";

import { MeResponse } from "../../../types/api/User";

interface Props {
  pathToCreate?: string;
  pathToUpdate?: string;
  pageSubHeader: string;
  createButtonLabel?: string;
  isSystemAdmin?: boolean | null;
  isClientAdmin?: boolean | null;
  currentUser?: MeResponse | null;
}

export default function ClientList({
  pathToCreate,
  pathToUpdate,
  pageSubHeader,
  createButtonLabel,
  isSystemAdmin = false,
  isClientAdmin = false,
  currentUser = null,
}: Props) {
  const [deleteClient, { isLoading: deleteLoading }] = useDeleteClientMutation();

  const { confirmationDialogProps, openDialog } = useConfirmDialog({
    text: dictionary.admin.deleteClientConfirmText,
  });

  const { data: clients, isLoading } = useGetClientListQuery();

  const updateIsDisabled = false;

  const handleDelete = (id: string) => () => {
    openDialog(() => {
      deleteClient({
        id: id,
      });
    });
  };

  return (
    <Paper sx={{ p: 4, mt: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <PageSubHeader text={pageSubHeader} />
        {pathToCreate && isSystemAdmin && (
          <Button component={Link} to={pathToCreate} sx={{ paddingTop: "4px" }}>
            <AddIcon /> {createButtonLabel}
          </Button>
        )}
      </Box>
      <DataGridPremium
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          pinnedColumns: {
            right: ["actions"],
          },
          columns: {
            columnVisibilityModel: {
              actions: !updateIsDisabled,
            },
          },
        }}
        pagination
        pageSizeOptions={[5,10, 25, 50, 100]}
        loading={isLoading || deleteLoading}
        columns={getListColumns(
          Boolean(isSystemAdmin),
          Boolean(isClientAdmin) || Boolean(isSystemAdmin),
          isSystemAdmin ? handleDelete : undefined,
          (isSystemAdmin || isClientAdmin) ? pathToUpdate : undefined,
        )}
        rows={clients || []}
        disableRowSelectionOnClick
        sx={{
          minHeight: "100px",
          "& .MuiDataGrid-virtualScroller": {
            minHeight: "100px",
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            paddingRight: "0 !important",
          },
        }}
        getRowId={(row: any) => row?.id}
      />
      <ConfirmationDialog {...confirmationDialogProps} />
    </Paper>
  );
}
